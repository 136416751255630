export const ekrNavLinks = [
  {
    label: 'projeler',
    pathname: '/ekerinsaat/projelerimiz',
  },
  {
    label: 'hakkımızda',
    pathname: '/ekerinsaat/hakkimizda',
    // hash: '#details',
  },
  {
    label: 'yönetim',
    pathname: '/ekerinsaat/',
    hash: '#yonetim',
  },
  {
    label: 'iletisim',
    pathname: '/iletisim',
  },
];

export const introNavLinks = [
  {
    label: 'anasayfa',
    pathname: '/',
  },
  {
    label: 'iletisim',
    pathname: '/iletisim',
  },
];

export const gryNavLinks = [
  {
    label: 'projeler',
    pathname: '/projelerimiz',
  },
  {
    label: 'hakkımızda',
    pathname: '/hakkimizda',
  },
  {
    label: 'yonetim',
    pathname: '/yonetim',
  },
  {
    label: 'ekip',
    pathname: '/',
    hash: '#ekip',
  },
  {
    label: 'iletisim',
    pathname: '/iletisim',
  },
];

export const socialLinks = [
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/greyhousemimarlik/',
    icon: 'instagram',
  }
];
